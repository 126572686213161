<template>
  <div class="coupon-exchange-setting">
    <PageTitle
      title="會員中心色系主題設定"
      btn="新增自定義色系主題"
      btn2="啟用色系主題"
      icon="chevron_left"
      @iconClick="$router.push({name: 'MembersCenter'})"
      @btnClick="onCreate"
      @btn2Click="modal.enabledTheme = true"
    />
    <MemberCenterColorThemeTable
      v-loading="loading.table"
      :configData="configData"
      :tableData="tableData"
      @refresh="refresh(false)"
    />

    <EnableMemberCenterColorThemeModal
      v-if="modal.enabledTheme"
      :configData="configData"
      :tableData="tableData"
      @close="modal.enabledTheme = false"
      @refresh="refresh"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberCenterColorThemeTable from './MemberCenterColorThemeTable.vue'
import EnableMemberCenterColorThemeModal from './EnableMemberCenterColorThemeModal.vue'
import { GetMemberCenterColorTheme, GetMemberCenterColorThemeCount } from '@/api/memberCenterColorTheme'
import { GetClientPageConfig } from '@/api/member/memberCenter'
import { systemMemberCenterColorThemesTemplate } from '@/config/memberCenter'
import { useFetch } from '@/use/fetch'

export default defineComponent({
  name: 'MemberCenterColorThemeSetting',
  components: {
    PageTitle,
    MemberCenterColorThemeTable,
    EnableMemberCenterColorThemeModal,
  },
  setup (props) {
    const router = useRouter()
    const { simpleFetch } = useFetch()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()
    const configData = ref({})

    const modal = reactive({
      enabledTheme: false,
    })

    const onCreate = () => {
      router.push({ name: 'MemberCenterColorThemeEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.all([
        simpleFetch(GetClientPageConfig, payload, (res) => {
          configData.value = res
        }),
        fetchData(GetMemberCenterColorTheme, payload),
        fetchDataCount(GetMemberCenterColorThemeCount, payload),
      ])

      tableData.value.unshift(...systemMemberCenterColorThemesTemplate)
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      modal,
      configData,
    }
  },
})
</script>
